import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '../../../../components/button/button';
import { useDisconnect, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { formatNumber, shortenAddress } from '../../../../utils';
import Modal from '../../../../components/modal/modal';

import usdt from '../../images/USDT.png';
import bnb from '../../images/bnb.png';
import wallet from '../../images/wallet.png';
import PurchaseHistoryModal from './purchase-history-modal/purchase-history-modal';

import './purchase-coins.scss';

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS as `0x${string}`;

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount)',
  'event Transfer(address indexed from, address indexed to, uint amount)',
];

const PurchaseCoins = ({
  address,
  availableTokens,
  tokensSold,
  tokenPrice,
  reneWalletAddress,
  minInvestmentUsdt,
  setIsPurchase,
}: {
  address: `0x${string}`;
  availableTokens: number;
  tokensSold: number | undefined;
  tokenPrice: number | undefined;
  reneWalletAddress: string;
  minInvestmentUsdt: number;
  setIsPurchase: Dispatch<SetStateAction<string>>;
}) => {
  const [input, setInput] = useState('');
  const [userAmount, setUserAmount] = useState('');
  const { isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let insufficientAmount = '';

  useEffect(() => {
    async function getBalance() {
      if (!isConnected || !walletProvider) return;
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const USDTContract = new Contract(tokenAddress, USDTAbi, signer);
        const USDTBalance = await USDTContract.balanceOf(address);
        setUserAmount(formatUnits(USDTBalance, 18));
      } catch (error) {
        console.warn('Failed to get balance:', error);
      }
    }

    getBalance();
  }, [address, isConnected, walletProvider, chainId, disconnect]);

  if (userAmount) {
    insufficientAmount = Number(input) > Number(userAmount) ? ' Insufficient USDT balance' : '';
  }

  if (input && Number(input) < minInvestmentUsdt) {
    insufficientAmount = `Minimal investment amount = ${minInvestmentUsdt}`;
  }
  const purchaseReneToken = formatNumber(
    Math.floor(input && tokenPrice ? Number(input) / tokenPrice : 0),
  );
  const formatTokens = availableTokens && formatNumber(availableTokens);
  const minimalReneToken = tokenPrice && formatNumber(Math.floor(minInvestmentUsdt / tokenPrice));
  const notOnBNBChain = chainId !== 97 && chainId !== 56;
  return (
    <div className="purchase-coins">
      {tokensSold && tokensSold < 50 ? (
        <div className="purchase-coins__progressbar">
          <p>
            Available: <span>{formatTokens}</span>
          </p>

          <div className="purchase-coins__progressbar-container">
            <div
              className="purchase-coins__progressbar-filled"
              style={{ width: `${tokensSold}%` }}
            ></div>
          </div>
        </div>
      ) : null}
      <div className="purchase-coins__input">
        <div className="purchase-coins__input_header">
          <p>
            <img src={wallet} alt="wallet" />
            <span>{shortenAddress(address)}</span>
          </p>
          <div>
            <Button variant="text" onClick={() => setIsModalOpen(true)}>
              Purchase History
            </Button>
            <Button variant="text" onClick={() => disconnect()}>
              Disconnect
            </Button>
          </div>
        </div>
        <div className="purchase-coins__input_amount">
          <div className="purchase-coins__input_amount_header">
            <p>
              Available: <span>{userAmount} USDT</span>
            </p>
            <Button variant="line" onClick={() => setInput(userAmount)}>
              Max
            </Button>
          </div>
          <div className="purchase-coins__input_amount_input">
            <div>
              <img src={usdt} alt="usdt" /> <p>USDT</p>
            </div>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={`Min ${minInvestmentUsdt || ''}`}
              type="number"
            />
          </div>
          <div className="purchase-coins__input_amount_sum">
            {!!insufficientAmount ? (
              <p className="purchase-coins__input_amount_sum_insufficient">{insufficientAmount}</p>
            ) : (
              <p
                className={
                  purchaseReneToken !== '0'
                    ? `purchase-coins__input_amount_sum_sufficient`
                    : 'purchase-coins__input_amount_sum_sufficient_blank'
                }
              >
                = {purchaseReneToken || minimalReneToken} RENE
              </p>
            )}
          </div>
        </div>
      </div>
      {notOnBNBChain ? (
        <p className="purchase-coins__switch-chain">You need to switch to BNB network</p>
      ) : null}
      <div className="purchase-coins__purchase">
        <Button
          variant="secondary"
          disabled={!!insufficientAmount || !input || !reneWalletAddress || notOnBNBChain}
          onClick={() => setIsPurchase(input)}
        >
          Buy RENE
        </Button>
        <p>
          Tokens sold through <img src={bnb} alt="bnb" /> BNB network with USDT
        </p>
        <p>
          Token vesting conditions apply: 5% unlock at Token Generation Event (TGE) with a 3-month
          cliff and subsequent 12-month vesting schedule
        </p>
      </div>
      <Modal isOpen={isModalOpen}>
        <PurchaseHistoryModal address={address} setCloseModal={() => setIsModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default PurchaseCoins;
