import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import './modal.scss';

interface Props {
  isOpen: boolean;
  children: JSX.Element;
}

const RenderModal = ({ children }: { children: JSX.Element }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return <div className="modal">{children}</div>;
};

const Modal: React.FC<Props> = ({ children, isOpen }) => {
  const el = document.getElementById('root')!;
  if (!isOpen) return null;
  return ReactDOM.createPortal(<RenderModal children={children} />, el);
};

export default Modal;
