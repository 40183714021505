import { Dispatch, SetStateAction, useState } from 'react';
import { BrowserProvider, Contract, parseUnits } from 'ethers';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { Button } from '../../../../components/button/button';
import { shortenAddress } from '../../../../utils';
import axiosPrivateSale from '../../../../global/axios/axiosTokenSale';
import Checkbox from '../../../../components/checkbox/checkbox';
import usdt from '../../images/USDT.png';
import rene from '../../images/rene.png';
import Terms from './terms/terms';
import Modal from '../../../../components/modal/modal';
import rejected from '../../images/error.png';
import Spinner from '../../../../components/spinner/spinner';
import PurchaseComplete from '../purchase-complete/purchase-complete';

import './confirm-purchase.scss';

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS as `0x${string}`;

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount)',
  'event Transfer(address indexed from, address indexed to, uint amount)',
];

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
      fill="#F5F5F5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
      fill="#F5F5F5"
    />
  </svg>
);

const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      d="M9.83325 9.33325L16.4999 15.9999L9.83325 22.6666"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.8333 9.33325L24.4999 15.9999L17.8333 22.6666"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ConfirmPurchase = ({
  amount,
  address,
  tokenPrice,
  reneWalletAddress,
  reneAllocation,
  setPurchaseAmount,
}: {
  amount: string;
  address: string;
  tokenPrice: number;
  reneAllocation: number;
  reneWalletAddress: string;
  setPurchaseAmount: Dispatch<SetStateAction<string>>;
}) => {
  const [error, setError] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [purchaseHash, setPurchaseHash] = useState('');
  const purchaseReneToken = Math.floor(Number(amount) / tokenPrice);

  const sendUSDT = async () => {
    if (!walletProvider || !amount) return;
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const USDTContract = new Contract(tokenAddress, USDTAbi, signer);

    // Convert the input amount to the correct units (assuming USDT has 6 decimals)
    const amountInWei = parseUnits(amount.toString(), 18);
    try {
      const transactionResponse = await USDTContract.transfer(reneWalletAddress, amountInWei);
      console.log('Transaction submitted, waiting for confirmation...');
      const receipt = await transactionResponse.wait(4);
      if (receipt.hash) {
        setPurchaseHash(receipt.hash);
        axiosPrivateSale
          .post('private-sale-transaction', {
            transactionHash: receipt.hash,
          })
          .catch((err) => console.log(err));
      }
    } catch (error: any) {
      console.error('Transaction failed:', error);
      if (error.code === 4001) {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (purchaseHash) {
    return (
      <PurchaseComplete
        hash={purchaseHash}
        amount={amount}
        reneTokens={purchaseReneToken}
        address={address}
        close={() => setPurchaseAmount('')}
      />
    );
  }

  return (
    <div className="confirm-purchase">
      <button onClick={() => setPurchaseAmount('')}>
        <Close />
      </button>
      <div className="confirm-purchase__tokens">
        <div className="confirm-purchase__tokens_usdt">
          <img src={usdt} alt="usdt" />
          <p>Pay</p>
          <p>{amount} USDT</p>
        </div>
        <ChevronRight />
        <div className="confirm-purchase__tokens_rene">
          <img src={rene} alt="usdt" />
          <p>Receive</p>
          <p>{purchaseReneToken} RENE</p>
        </div>
      </div>
      <div className="confirm-purchase__info">
        <div>
          <p>Pay from</p>
          <p>{shortenAddress(address)}</p>
        </div>
        <div>
          <p>Token price</p>
          <p>{tokenPrice} USDT</p>
        </div>
      </div>
      <div className="confirm-purchase__terms">
        <Terms tokenAmount={reneAllocation} tokenPrice={tokenPrice} />
      </div>
      <div className="confirm-purchase__action">
        <div>
          <Checkbox value={isTermsChecked} setValue={() => setIsTermsChecked(!isTermsChecked)}>
            I agree to the Terms of Service
          </Checkbox>
        </div>
        <Button variant="secondary" disabled={!isTermsChecked} onClick={sendUSDT}>
          {isLoading ? <Spinner /> : 'Confirm purchase'}
        </Button>
      </div>
      <Modal isOpen={error}>
        <div className="purchase-error-modal">
          <button type="button" onClick={() => setError(false)}>
            <Close />
          </button>
          <div>
            <img src={rejected} alt="error" />
            <h1>Something went wrong</h1>
          </div>
          <p>
            Please try again later, or <a href="mailto:support@reneverse.io">contact our support</a>{' '}
            for assistance.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmPurchase;
