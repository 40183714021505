import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../global/routes';
import { useEffect, useState } from 'react';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { LinkButton } from '../../components/button/button';
import CountdownTimer from '../../components/countdown-timer/countdown-timer';
import NftDropScale from './scale/nft-drop-scale';
import logo from '../../assets/images/logo/reneverse.svg';
import mainImg from './images/nft-transition.webp';
import Spinner from '../../components/spinner/spinner';
import loadingMint from './images/spinner.png';
import video from './images/nft-transition.webm';

import './nft-drop-page.scss';

const API_URL = process.env.REACT_APP_API as string;
type WhitelistStatus = 'GUARANTEED' | 'FCFS' | 'PUBLIC';

const preMintStatus = {
  GUARANTEED: 'You Are On the GUARANTEED WHITELIST!',
  FCFS: 'You Are On the FCFS WHITELIST!',
  PUBLIC: 'You Are Not On the WHITELIST',
};

const mintStatus = {
  GUARANTEED: '',
  FCFS: "You're in the FCFS Phase & can't mint right now. Feel free to come back at the end of this timer!",
  PUBLIC:
    "You're not on the WL. If any NFTs remain, you're welcome to try and mint again during the Public Mint Phase",
};

interface MintPhase {
  mintStats: {
    minted: number;
    totalSupply: number;
  } | null;
  phases: {
    title: string | null;
    endTime: number;
    isMinting: boolean;
    phaseType: string | null;
    counterLabel: string;
  }[];
}

interface WalletStatus {
  mintStatus: 'NOT_MINTED' | 'PENDING' | 'MINTED';
  tokenId: string;
  revealEndTime: number;
  transactionHash: string;
  walletAddress: string;
  whitelistStatus: WhitelistStatus;
  mintInitiatedAt?: number;
  error?: string;
}

// dummy data for testing purposes
// const addSeconds = 10;
// const testData = {
//   phases: [
//     {
//       title: null,
//       counterLabel: 'Minting starts in',
//       endTime: new Date(new Date().getTime() + addSeconds * 1000).getTime(),
//       isMinting: false,
//       phaseType: 'PREMINT',
//     },
//     {
//       title: 'Guaranteed whitelist now minting',
//       counterLabel: 'Guaranteed Mint Ends In',
//       endTime: new Date(new Date().getTime() + addSeconds * 2 * 1000).getTime(),
//       isMinting: true,
//       phaseType: 'GUARANTEED',
//     },
//     {
//       title: 'FCFS WL now minting',
//       counterLabel: 'FCFS Mint Ends In',
//       endTime: new Date(new Date().getTime() + addSeconds * 3 * 1000).getTime(),
//       isMinting: true,
//       phaseType: 'FCFS',
//     },
//     {
//       title: 'Public mint now live',
//       counterLabel: 'Public Mint Ends In',
//       endTime: new Date(new Date().getTime() + addSeconds * 4 * 1000).getTime(),
//       isMinting: true,
//       phaseType: 'PUBLIC',
//     },
//     {
//       title: '',
//       counterLabel: '',
//       endTime: 0,
//       isMinting: false,
//       phaseType: 'POSTMINT',
//     },
//   ],
//   mintStats: null,
// };

const PreMint = ({
  isConnected,
  whitelistStatus,
}: {
  isConnected: boolean;
  whitelistStatus: WhitelistStatus | undefined;
}) => {
  const { open } = useWeb3Modal();

  return (
    <section className="nft-drop-page__footer">
      <div className="nft-drop-page__actions">
        {isConnected && whitelistStatus ? (
          <div className="nft-drop-page__info">
            {preMintStatus[whitelistStatus as WhitelistStatus]}
          </div>
        ) : (
          <button className="nft-drop-page__btn nft-drop-page__btn--primary" onClick={() => open()}>
            Connect Wallet
          </button>
        )}
      </div>
    </section>
  );
};

const Mint = ({
  nft,
  address,
  whitelistStatus,
  isConnected,
  mintStats,
  currentPhaseType,
  setWalletStatus,
  setIsSoldOut,
}: {
  nft: string | undefined;
  address: `0x${string}` | undefined;
  whitelistStatus: WhitelistStatus | undefined;
  mintStats: MintPhase['mintStats'] | undefined;
  isConnected: boolean;
  currentPhaseType: WhitelistStatus;
  setIsSoldOut: React.Dispatch<React.SetStateAction<boolean>>;
  setWalletStatus: React.Dispatch<React.SetStateAction<WalletStatus | undefined>>;
}) => {
  const { open } = useWeb3Modal();
  const [loading, setLoading] = useState(false);

  const mintNft = () => {
    if (!address) return;
    setLoading(true);
    axios
      .post<WalletStatus>(`${API_URL}nft-drop-mint?walletAddress=${address}`)
      .then(async ({ data }) => {
        if (data?.error) {
          setIsSoldOut(true);
        }
        await setWalletStatus(data);
        setLoading(false);
      });
  };

  const isAbleToMint = () => {
    switch (whitelistStatus) {
      case 'GUARANTEED':
        return (
          currentPhaseType === 'GUARANTEED' ||
          currentPhaseType === 'FCFS' ||
          currentPhaseType === 'PUBLIC'
        );
      case 'FCFS':
        return currentPhaseType === 'FCFS' || currentPhaseType === 'PUBLIC';
      case 'PUBLIC':
        return currentPhaseType === 'PUBLIC';
      default:
        return false;
    }
  };

  const showMintButton = () => {
    if (!isConnected) {
      return (
        <button className="nft-drop-page__btn nft-drop-page__btn--primary" onClick={() => open()}>
          Connect Wallet
        </button>
      );
    }

    if (isConnected && !nft && isAbleToMint()) {
      return (
        <button className="nft-drop-page__btn nft-drop-page__btn--primary" onClick={mintNft}>
          {loading ? <Spinner /> : 'Mint NFT'}
        </button>
      );
    }

    if (isConnected && !nft && !isAbleToMint() && whitelistStatus) {
      return (
        <div className="nft-drop-page__info">{mintStatus[whitelistStatus as WhitelistStatus]}</div>
      );
    }
  };

  return (
    <section className="nft-drop-page__footer">
      <div className="nft-drop-page__actions">{showMintButton()}</div>
      {mintStats ? (
        <NftDropScale value={mintStats.minted} max={mintStats.totalSupply} label="Already Minted" />
      ) : null}
    </section>
  );
};

const PostMint = ({
  address,
  isConnected,
}: {
  address: `0x${string}` | undefined;
  isConnected: boolean;
}) => {
  const { open } = useWeb3Modal();

  return (
    <section className="nft-drop-page__footer">
      <div className="nft-drop-page__actions">
        {isConnected ? (
          <LinkButton to={`nft`} className="nft-drop-page__btn nft-drop-page__btn--tertiary">
            Check Your Tier
          </LinkButton>
        ) : (
          <button className="nft-drop-page__btn nft-drop-page__btn--primary" onClick={() => open()}>
            Connect Wallet
          </button>
        )}
      </div>
    </section>
  );
};

const NftDropPage = () => {
  const navigate = useNavigate();

  const { address, isConnected } = useWeb3ModalAccount();
  const [nftDropPhases, setNftDropPhases] = useState<MintPhase>();
  const [currentNftDropPhase, setCurrentNftDropPhase] = useState<MintPhase['phases'][0]>();
  const [walletStatus, setWalletStatus] = useState<WalletStatus>();
  const [isSoldOut, setIsSoldOut] = useState(false);

  useEffect(() => {
    axios.get<MintPhase>(`${API_URL}active-nft-drop`).then(({ data }) => {
      if (data.mintStats && data.mintStats.minted === data.mintStats.totalSupply) {
        setIsSoldOut(true);
      }
      setNftDropPhases(data);
      setCurrentNftDropPhase(getNftDropPhase(data));
    });
  }, []);

  useEffect(() => {
    if (address) {
      axios
        .get<WalletStatus>(`${API_URL}nft-drop-info?walletAddress=${address}`)
        .then(({ data }) => {
          setWalletStatus(data);
        });
    }
  }, [address]);

  useEffect(() => {
    if (walletStatus && walletStatus.mintStatus === 'MINTED') {
      navigate(`${Pages.NFT_DROP}/nft`);
    }
  }, [address, navigate, walletStatus]);

  const handleCountdownTimerEnd = () => {
    if (nftDropPhases?.phases) {
      setCurrentNftDropPhase(getNftDropPhase(nftDropPhases));
    }
  };

  const getNftDropPhase = (nftDropPhases: MintPhase) => {
    const now = new Date().getTime() + 1000;
    return (
      nftDropPhases?.phases.find((phase) => phase.endTime >= now) || nftDropPhases?.phases.at(-1)
    );
  };

  if (isConnected && walletStatus?.mintStatus === 'PENDING') {
    return (
      <div className="nft-drop-page__minting">
        <img src={logo} alt="reneverse" />
        <img src={loadingMint} alt="loading" />
        <div>
          <p>Minting Your</p>
          <h2>ReneVerse Founder’s Key</h2>
          <h3>
            Don’t worry, we’ve confirmed your Mint Request, and you’ll soon see the NFT in your
            wallet. Feel free to close this tab & check back here periodically to see if your
            Founder’s Key has been minted.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="nft-drop-page">
      <section className="nft-drop-page__header">
        <img src={logo} alt="reneverse" />
        {<h1>{!isSoldOut ? currentNftDropPhase?.title : ''}</h1>}
      </section>
      <section className="nft-drop-page__main">
        <h2 className="nft-drop-page__main-title nft-drop-page__main-title--first">ReneVerse</h2>
        <h2 className="nft-drop-page__main-title nft-drop-page__main-title--second">
          {isSoldOut || currentNftDropPhase?.phaseType === 'POSTMINT'
            ? 'SOLD OUT'
            : "Founder's Key"}
        </h2>
        <div className="nft-drop-page__main-content">
          <video autoPlay loop muted playsInline poster={mainImg} preload="none">
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </section>
      <section className="nft-drop-page__timer">
        {currentNftDropPhase?.endTime && !isSoldOut ? (
          <>
            <span className="nft-drop-page__timer-label">{currentNftDropPhase?.counterLabel}</span>
            <CountdownTimer
              targetDate={new Date(currentNftDropPhase?.endTime).toISOString()}
              countdownEndHandler={handleCountdownTimerEnd}
            />
          </>
        ) : null}
      </section>
      {currentNftDropPhase?.phaseType === 'PREMINT' ? (
        <PreMint isConnected={isConnected} whitelistStatus={walletStatus?.whitelistStatus} />
      ) : null}
      {(currentNftDropPhase?.phaseType === 'GUARANTEED' ||
        currentNftDropPhase?.phaseType === 'FCFS' ||
        currentNftDropPhase?.phaseType === 'PUBLIC') &&
      !isSoldOut ? (
        <Mint
          address={address}
          isConnected={isConnected}
          nft={walletStatus?.tokenId}
          mintStats={nftDropPhases?.mintStats}
          whitelistStatus={walletStatus?.whitelistStatus}
          currentPhaseType={currentNftDropPhase.phaseType}
          setIsSoldOut={setIsSoldOut}
          setWalletStatus={setWalletStatus}
        />
      ) : null}
      {currentNftDropPhase?.phaseType === 'POSTMINT' || isSoldOut ? (
        <PostMint isConnected={isConnected} address={address} />
      ) : null}
    </div>
  );
};

export default NftDropPage;
