import { FC } from 'react';
import { Outlet } from 'react-router';
import { LinkButton } from '../button/button';
import { Pages } from '../../global/routes';
import Footer from '../footer/footer';
import Header from '../header/header';

import './landing-page-layout.scss';

const LandingPageLayout: FC = () => {
  return (
    <div className="layout">
      <div className="promo">
        <LinkButton to={Pages.BOOK_DEMO} variant="text">
          Join our Pilot and get discounted impressions from 1M+ gamers
        </LinkButton>
      </div>

      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
