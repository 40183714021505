import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Buffer } from 'buffer';
import { LiveChatWidget } from '@livechat/widget-react';
import { useProdEffect } from './global/hooks/useProdEffect';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Pages } from './global/routes';
import LandingPageLayout from './components/landing-page-layout/landing-page-layout';

import {
  HomePage,
  AboutPage,
  PrivacyPolicyPage,
  DocsPage,
  StatsPage,
  AdvertisersPage,
  PublishersPage,
  BookDemoPage,
  GameDemoPage,
  NftDropPage,
  MintedNftPage,
  TokenSalePage,
} from './pages';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import Sellers from './pages/selers/selers';

// 1. Get projectId
const projectId = '86713826d11d89f8a5ea72b552ea8954';
// 2. Set chains
const bsc = {
  chainId: 56,
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/',
};

const bscTest = {
  chainId: 97,
  name: 'Binance Smart Chain TestNet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://rpc.ankr.com/bsc',
};

const blast = {
  chainId: 81457,
  name: 'Blast Mainnet',
  currency: 'ETH',
  explorerUrl: 'https://blastscan.io',
  rpcUrl: 'https://rpc.blast.io',
};

const blastTest = {
  chainId: 168587773,
  name: 'Blast Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://testnet.blastscan.io',
  rpcUrl: 'https://sepolia.blast.io',
};

// 3. Create a metadata object
const metadata = {
  name: 'Reneverse',
  description: 'Gaming without borders',
  url: 'https://reneverse.io',
  icons: ['https://cdn.reneverse.io/logos/'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
});
const chain = process.env.REACT_APP_CHAIN_MAINNET ? [bsc, blast] : [bscTest, blastTest];

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: chain,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

window.Buffer = window.Buffer || Buffer;

const GA_ID: string = process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string;
const GTM_ID: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
const LIVECHAT_LICENSE: string = process.env.REACT_APP_LIVECHAT_LICENSE as string;

function App() {
  const location = useLocation();

  useProdEffect(() => {
    ReactGA.initialize(GA_ID);
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useProdEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.key]);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<LandingPageLayout />}>
          <Route path={Pages.HOME} element={<HomePage />} />
          <Route path={Pages.PUBLISHERS} element={<PublishersPage />} />
          <Route path={Pages.ADVERTISERS} element={<AdvertisersPage />} />
          <Route path={Pages.DOCS_FAQ} element={<DocsPage />} />
          <Route path={Pages.ABOUT} element={<AboutPage />} />
          <Route path={Pages.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={Pages.BOOK_DEMO} element={<BookDemoPage />} />
          <Route path={Pages.GAME_DEMO} element={<GameDemoPage />} />
        </Route>
        <Route path={Pages.TOKEN_SALE} element={<TokenSalePage />} />
        <Route path={Pages.NFT_DROP} element={<NftDropPage />} />
        <Route path={`${Pages.NFT_DROP}/nft`} element={<MintedNftPage />} />
        <Route path={Pages.STATS} element={<StatsPage />} />
        <Route path="/sellers.json" element={<Sellers />} />
        <Route path="*" element={<Navigate to={Pages.HOME} />} />
      </Routes>
      <LiveChatWidget license={LIVECHAT_LICENSE} visibility="minimized" />
    </HelmetProvider>
  );
}

export default App;
