import './nft-drop-scale.scss';

interface NftDropScaleProps {
  max: number;
  value: number;
  label: string;
}

const NftDropScale = ({ max, value, label }: NftDropScaleProps) => {
  const valueStyle = { width: `${(value * 100) / max }%` };
  return (
    <div className="nft-drop-scale">
      {`${value}/${max} ${label}`}
      <div className="nft-drop-scale__value" style={valueStyle} />
    </div>
  );
};

export default NftDropScale;
