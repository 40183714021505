import { useEffect, useState } from 'react';
import { formatDate, shortenAddress } from '../../../../../utils';
import { ExternalLinkButton } from '../../../../../components/button/button';
import Icon from '../../../../../components/icon/icon';
import Spinner from '../../../../../components/spinner/spinner';
import axiosPrivateSale from '../../../../../global/axios/axiosTokenSale';
import './purchase-history-modal.scss';

const isMainnet = !!process.env.REACT_APP_CHAIN_MAINNET;

type PurchaseHistory = {
  transactions: {
    transactionHash: string;
    smartContractTransactionHash: string;
    amountUsdt: number;
    amountRene: number;
    createdAt: Date;
  }[];
  totalRene: number;
  totalUsdt: number;
  walletAddress: number;
};

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
      fill="#F5F5F5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
      fill="#F5F5F5"
    />
  </svg>
);

const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.66663 4.6665L7.99996 7.99984L4.66663 11.3332"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.66663 4.6665L12 7.99984L8.66663 11.3332"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Purchase = ({ purchase }: { purchase: PurchaseHistory['transactions'][0] }) => {
  const hashLink = isMainnet ? 'https://bscscan.com/tx' : 'https://testnet.bscscan.com/tx';
  return (
    <div className="purchase-history-modal__main_list_purchase">
      <div className="purchase-history-modal__main_list_purchase_info">
        <p>{formatDate(new Date(purchase.createdAt))}</p>
        <div>
          <p>{purchase.amountUsdt} USDT</p>
          <ChevronRight />
          <p>{purchase.amountRene} RENE</p>
        </div>
      </div>
      <div className="purchase-history-modal__main_list_purchase_hash">
        <p>
          USDT transaction details:
          <ExternalLinkButton variant="text" href={`${hashLink}/${purchase.transactionHash}`}>
            <span>{shortenAddress(purchase.transactionHash)}</span>
            <Icon name="Link" />
          </ExternalLinkButton>
        </p>
        {purchase.smartContractTransactionHash && (
          <p>
            RENE reservation details:
            <ExternalLinkButton
              variant="text"
              href={`${hashLink}/${purchase.smartContractTransactionHash}`}
            >
              <span>{shortenAddress(purchase.smartContractTransactionHash)}</span>
              <Icon name="Link" />
            </ExternalLinkButton>
          </p>
        )}
      </div>
    </div>
  );
};

const PurchaseHistoryModal = ({
  address,
  setCloseModal,
}: {
  address: string;
  setCloseModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistory>();

  useEffect(() => {
    if (!purchaseHistory && address) {
      setIsLoading(true);
      axiosPrivateSale
        .get(`private-sale-purchase-history?walletAddress=${address}`)
        .then((data: { data: PurchaseHistory }) => {
          setPurchaseHistory(data.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [address, purchaseHistory]);

  return (
    <div className="purchase-history-modal">
      <div className="purchase-history-modal__heading">
        <h2>Purchase history</h2>
        <button type="button" onClick={setCloseModal}>
          <Close />
        </button>
      </div>
      <div className="purchase-history-modal__main">
        <div className="purchase-history-modal__main_list">
          {purchaseHistory?.transactions.map((purchase) => (
            <Purchase key={purchase.transactionHash} purchase={purchase} />
          ))}
          {isLoading ? <Spinner /> : null}
        </div>
      </div>
      <div className="purchase-history-modal__total">
        <p>Total</p>
        <div>
          <p>{purchaseHistory?.totalUsdt} USDT</p>
          <ChevronRight />
          <p>{purchaseHistory?.totalRene} RENE</p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistoryModal;
