import { FC, useEffect, useState } from 'react';
import { useDisconnect, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { NavLink, useLocation } from 'react-router-dom';
import { ExternalLinkButton } from '../button/button';
import { RENE_PORTAL_URL } from '../../global/constants';
import { Pages } from '../../global/routes';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import Icon from '../icon/icon';
import Menu from '../menu/menu';
import ReneLogo from '../rene-logo';

import './header.scss';

interface HamburgerMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const HamburgerIcon: FC<HamburgerMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => (
  <div className="header__hamburger-icon">
    <button type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <Icon name="Hamburger" label="Hamburger Menu Icon" />
    </button>
  </div>
);

const Header = () => {
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [color, setColor] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 70) {
        setColor(true);
      } else {
        setColor(false);
      }
    };
    window.addEventListener('scroll', changeColor);
    return () => {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  useEffect(() => {
    if (walletProvider) disconnect();
  }, [disconnect, walletProvider]);

  useEffect(() => {
    // WE have a header with fixed position, so we need to reset the scroll position to top when user changes the page
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }, [location.key]);

  return (
    <header className={color ? 'header header--color' : 'header'} style={{ top: '50px' }}>
      <div className="header__container">
        <NavLink to={Pages.HOME}>
          <ReneLogo />
        </NavLink>
        <div className="header__container--right">
          <Menu className="header__links" location={location} />
          <ExternalLinkButton variant="secondary" size="lg" href={RENE_PORTAL_URL}>
            Visit Portal
          </ExternalLinkButton>
          <HamburgerIcon isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>
      </div>

      <HamburgerMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
        <Menu className="header__links" location={location}>
          <NavLink to={Pages.PRIVACY_POLICY}>Privacy Policy</NavLink>
        </Menu>
      </HamburgerMenu>
    </header>
  );
};

export default Header;
