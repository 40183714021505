import { Pages } from '../../global/routes';
import { RENE_PORTAL_URL } from '../../global/constants';
import { ExternalLinkButton, LinkButton } from '../../components/button/button';
import HeadMeta from '../../components/head-meta';
import Card from '../../components/carousel/card/card';
import Animate from '../../components/animate/animate';
import Carousel from '../../components/carousel/carousel';
import ResponsiveImage from '../../components/responsive-image';

import sdk from './images/sdk.webp';
import slide1 from './images/card-1.webp';
import slide2 from './images/card-2.webp';
import slide3 from './images/card-3.webp';
import portal from './images/portal.webp';

import './publishers-page.scss';
import './animations.scss';

const slides = [
  {
    img: slide1,
    imgXl: slide1,
    title: 'Ease of Implementation',
    description: (
      <p>
        Integrating Borderless Ads in your game is a simple 3-step process of integrating the
        ReneVerse SDK, defining ad surfaces, and accepting brand campaigns.
      </p>
    ),
  },
  {
    img: slide2,
    imgXl: slide2,

    title: 'Ad Surface Ownership',
    description: (
      <p>
        Borderless Ads allows for developers and players to own and monetize assets containing
        Borderless Ad surfaces. <br />
        <br /> Ad surface ownership in this way is an industry-first functionality enabled by
        ReneVerse.
      </p>
    ),
  },
  {
    img: slide3,
    imgXl: slide3,

    title: 'Blockchain-Powered Rewards',
    description: (
      <p>
        Ad dollars earned from brand campaigns are redistributed among ReneVerse, the game
        developers who publish ads, and the players who engage with these ads.
      </p>
    ),
  },
];

const PublishersPage = () => {
  return (
    <div className="publishers-page">
      <HeadMeta title="Publishers page" description="Reneverse" page="home" />
      <div className="publishers-page__landing">
        <h1>
          Supercharge Your Game Revenue with <span>Borderless Ads</span>
        </h1>
        <div>
          <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
            Book a demo
          </LinkButton>
          <LinkButton to={Pages.DOCS_FAQ} variant="line">
            Read docs
          </LinkButton>
        </div>
      </div>
      <Animate animateClassName="animate-benefits" threshold={0.5}>
        <div className="publishers-page__benefits">
          <div>
            <h2>FOR YOUR GAME</h2>
            <h2>Benefits</h2>
          </div>
          <Carousel slides={slides} SlideComponent={Card} />
        </div>
      </Animate>
      <Animate animateClassName="animate-sdk">
        <div className="publishers-page__section">
          <ResponsiveImage sm={sdk} alt="sdk" />
          <div>
            <div>
              <h2>INTEGRATE IMMERSIVE ADS IN YOUR GAME</h2>
              <h2>SDK</h2>
            </div>
            <p>
              Connect your game, define ad surfaces, and manage your game’s Ownable Assets, all with
              the ReneVerse SDK inside your Unity Editor.
            </p>
            <LinkButton to={Pages.DOCS_FAQ} variant="secondary">
              Download SDK
            </LinkButton>
          </div>
        </div>
      </Animate>
      <Animate animateClassName="animate-portal">
        <div className="publishers-page__section">
          <ResponsiveImage sm={portal} alt="portal" />
          <div>
            <div>
              <h2>YOUR AD CAMPAIGN</h2>
              <h2>Portal</h2>
            </div>
            <p>
              Upload your ads, manage ongoing campaigns, and even add Branded Assets easily in the
              ReneVerse Portal - your one-stop shop for controlling your brand’s Borderless Ads.
            </p>
            <ExternalLinkButton variant="secondary" href={RENE_PORTAL_URL}>
              Visit Portal
            </ExternalLinkButton>
          </div>
        </div>
      </Animate>
      {/* <Animate animateClassName="animate-promo">
        <div className="publishers-page__promo">
          <div className="publishers-page__promo_text">
            <div>
              <h2>TUTORIAL</h2>
              <h2>Ads Integration </h2>
            </div>
            <div>
              <p>Getting started with integrating Borderless Ads in your game is quick and easy.</p>
              <div>
                <ExternalLinkButton variant="secondary">Book Demo</ExternalLinkButton>
                <ExternalLinkButton variant="line">Read Docs</ExternalLinkButton>
              </div>
            </div>
          </div>
          <video src=""></video>
        </div>
      </Animate> */}
      <Animate animateClassName="animate-signup">
        <div className="publishers-page__signup">
          <h3>Sign up for Pilot & earn preferential rates from participating brands.</h3>
          <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
            Sign up now
          </LinkButton>
        </div>
      </Animate>
    </div>
  );
};

export default PublishersPage;
