import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkButton } from '../../../components/button/button';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, Contract } from 'ethers';
import { Pages } from '../../../global/routes';
import spinner from '../images/spinner.png';
import CountdownTimer from '../../../components/countdown-timer/countdown-timer';
import logo from '../../../assets/images/logo/reneverse.svg';
import noNft from '../images/no-nft.svg';
import epic from '../images/epic.webp';
import borderless from '../images/borderless.webp';
import legendary from '../images/legendary.webp';

import opeanseaLogo from '../images/opensea.png';

import mainImg from '../images/mystery.webp';
import video from '../images/mystery-video.webm';

import './minted-nft.scss';

type WhitelistStatus = 'GUARANTEED' | 'FCFS' | 'PUBLIC';

interface WalletStatus {
  mintStatus: 'NOT_MINTED' | 'PENDING' | 'MINTED';
  tokenId: string;
  revealEndTime: number;
  transactionHash: string;
  walletAddress: string;
  whitelistStatus: WhitelistStatus;
  mintInitiatedAt?: string;
  metadata: {
    tier: 'BORDERLESS' | 'EPIC' | 'LEGENDARY';
  };
}

const API_URL = process.env.REACT_APP_API as string;

const RENE_KEYS_ABI = [
  'function updateMerkleRoot(bytes32 _merkleRoot) external',
  'function merkleRoot() public returns (bytes32)',
  'function getMerkleRoot() public view returns (bytes32)',
  'function claim(bytes32[] calldata _merkleProof, string memory tokenURI) public',
  'event MerkleRootUpdated(bytes32 indexed _oldMerkleRoot, bytes32 indexed _newMerkleRoot)',
  'function safeMint(address to, string memory uri, bytes32[] calldata _merkleProof) public',
  'function balanceOf(address owner) public view returns (uint256)',
  'event NFTClaimed(address indexed claimer, uint indexed tokenId)',
  'function ownerOf(uint256 tokenId) public view returns (address)',
];

const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS as `0x${string}`;
const openseaUrl = process.env.REACT_APP_CHAIN_MAINNET
  ? 'https://opensea.io/assets/blast'
  : 'https://testnets.opensea.io/assets/blast-sepolia';

const tiers = {
  BORDERLESS: {
    title: 'BORDERLESS',
    card: 'https://cdn.reneverse.io/nft-drop/tiers/borderless.mp4',
    poster: borderless,
  },
  EPIC: {
    title: 'EPIC',
    card: 'https://cdn.reneverse.io/nft-drop/tiers/epic.mp4',
    poster: epic,
  },
  LEGENDARY: {
    title: 'LEGENDARY',
    card: 'https://cdn.reneverse.io/nft-drop/tiers/legendary.mp4',
    poster: legendary,
  },
};

const NoNft = () => {
  return (
    <>
      <section className="minted-nft__header">
        <img src={logo} alt="reneverse" />
      </section>
      <div className="minted-nft__main minted-nft__no-nft">
        <section className="minted-nft__key">
          <img src={noNft} alt="no nft in the wallet" />
          <p>Looks like this wallet doesn’t have a Founder’s Key</p>
          <h1 className="minted-nft__key--no-key">Founder’s Key Not Owned </h1>
        </section>
        <section className="minted-nft__actions">
          {/* <LinkButton
            to={Pages.NFT_DROP}
            className="nft-drop-page__btn nft-drop-page__btn--secondary"
          >
            Back to Founder's Key
          </LinkButton> */}
          <LinkButton to={Pages.HOME} className="nft-drop-page__btn nft-drop-page__btn--primary">
            Check our website
          </LinkButton>
        </section>
      </div>
    </>
  );
};

const NftRevealPending = ({
  address,
  nftId,
  revealEndTime,
  setWalletStatus,
  setLoading,
}: {
  address: string | undefined;
  nftId: string;
  revealEndTime: number | undefined;
  setWalletStatus: React.Dispatch<React.SetStateAction<WalletStatus | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [counterDone, setCounterDone] = useState(false);

  useEffect(() => {
    if (counterDone && address)
      axios
        .get<WalletStatus>(`${API_URL}nft-drop-info?walletAddress=${address}`)
        .then(async ({ data }) => {
          await setWalletStatus(data);
          setLoading(false);
        });
  }, [address, counterDone, setLoading, setWalletStatus]);

  return (
    <>
      <section className="minted-nft__header">
        <img src={logo} alt="reneverse" />
      </section>
      <div className="minted-nft__main">
        <section className="minted-nft__key">
          <video autoPlay loop muted playsInline poster={mainImg} preload="none">
            <source src={video} type="video/mp4" />
          </video>
          <p>You have successfully minted a</p>
          <h1 className="minted-nft__key--epic">ReneVerse Founder’s Key</h1>
          <p>Reveal Your Key Tier / Rarity in</p>
        </section>
        <section className="minted-nft__timer">
          {revealEndTime ? (
            <CountdownTimer
              targetDate={new Date(revealEndTime).toISOString()}
              countdownEndHandler={() => setCounterDone(true)}
            />
          ) : null}
        </section>
        <section className="minted-nft__actions">
          {/* <LinkButton
            to={Pages.NFT_DROP}
            className="nft-drop-page__btn nft-drop-page__btn--secondary"
          >
            Back to Founder's Key
          </LinkButton> */}
          <LinkButton
            to={`${openseaUrl}/${contractAddress}/${nftId}`}
            className="nft-drop-page__btn nft-drop-page__btn--primary"
            target="_blank"
          >
            <img src={opeanseaLogo} alt="opeansea" />
            <p>Go to OpenSea</p>
          </LinkButton>
        </section>
      </div>
    </>
  );
};

const NftRevealed = ({
  metadata,
  nftId,
}: {
  metadata: WalletStatus['metadata'];
  nftId: string;
}) => {
  return (
    <>
      <section className="minted-nft__header">
        <img src={logo} alt="reneverse" />
      </section>
      <div className="minted-nft__main">
        <section className="minted-nft__key">
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={tiers[metadata.tier].poster}
            preload="none"
          >
            <source src={tiers[metadata.tier].card} type="video/mp4" />
          </video>
          <p>Your Founder’s Key Rarity</p>
          <h1 className="minted-nft__key--epic">{tiers[metadata.tier].title}</h1>
        </section>
        <section className="minted-nft__actions">
          {/* <LinkButton
            to={Pages.NFT_DROP}
            className="nft-drop-page__btn nft-drop-page__btn--secondary"
          >
            Back to Founder's Key
          </LinkButton> */}
          <LinkButton
            to={`${openseaUrl}/${contractAddress}/${nftId}`}
            className="nft-drop-page__btn nft-drop-page__btn--primary"
            target="_blank"
          >
            <img src={opeanseaLogo} alt="opeansea" />
            <p>Go to OpenSea</p>
          </LinkButton>
        </section>
      </div>
    </>
  );
};

const MintedNftPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const [noNftInWallet, setNoNftInWallet] = useState(false);
  const [walletStatus, setWalletStatus] = useState<WalletStatus | undefined>(location.state);
  const [loading, setLoading] = useState(false);

  const isReveal = walletStatus?.revealEndTime
    ? new Date().getTime() + 1000 >= walletStatus?.revealEndTime
    : false;

  useEffect(() => {
    async function getBalance() {
      if (!walletProvider || !walletStatus || !walletStatus?.tokenId) return;
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const contract = new Contract(contractAddress, RENE_KEYS_ABI, ethersProvider);
        const nftClaimed = await contract.ownerOf(walletStatus.tokenId);
        if (nftClaimed.toLowerCase() !== walletStatus.walletAddress.toLowerCase()) {
          setNoNftInWallet(true);
        }
      } catch (error) {
        setNoNftInWallet(true);
        console.warn('Failed to get balance:', error);
      }
    }

    getBalance();
  }, [walletProvider, chainId, walletStatus]);

  useEffect(() => {
    if (isConnected) {
      setLoading(true);
      axios
        .get<WalletStatus>(`${API_URL}nft-drop-info?walletAddress=${address}`)
        .then(async ({ data }) => {
          await setWalletStatus(data);
          setLoading(false);
        });
    }
  }, [address, isConnected]);

  useEffect(() => {
    if (!isConnected) {
      navigate(`${Pages.NFT_DROP}`);
    }
  }, [isConnected, navigate]);

  if (loading) {
    return (
      <div className="minted-nft minted-nft__loader">
        <section className="minted-nft__header">
          <img src={logo} alt="reneverse" />
        </section>
        <div className="minted-nft__main">
          <img src={spinner} alt="loading" />
        </div>
      </div>
    );
  }

  return (
    <div className="minted-nft">
      {walletStatus && (!walletStatus.tokenId || noNftInWallet) ? <NoNft /> : null}
      {walletStatus?.tokenId && !noNftInWallet && !walletStatus.metadata && !isReveal ? (
        <NftRevealPending
          address={address}
          nftId={walletStatus.tokenId}
          revealEndTime={walletStatus?.revealEndTime}
          setLoading={setLoading}
          setWalletStatus={setWalletStatus}
        />
      ) : null}
      {walletStatus?.tokenId &&
      walletStatus.metadata &&
      walletStatus.metadata.tier &&
      !noNftInWallet ? (
        <NftRevealed metadata={walletStatus.metadata} nftId={walletStatus.tokenId} />
      ) : null}
    </div>
  );
};

export default MintedNftPage;
