import { formatNumber } from '../../../../../utils';
import './terms.scss';

const Terms = ({ tokenAmount, tokenPrice }: { tokenAmount: number; tokenPrice: number }) => {
  return (
    <div className="terms">
      <h2>TOKEN PRIVATE SALE AGREEMENT</h2>
      <h3>
        This Agreement (the "Agreement") begins at the time (the "Effective Date") the purchaser
        selects ‘I agree to the terms of service’.
      </h3>

      <h4>PARTIES</h4>
      <ul>
        <li>
          LunaEdge Holdings Ltd, incorporated and registered in the British Virgin Islands with
          company number 2081456 whose registered office is at Quijano Chambers, P.O. Box 3159, Road
          Town, Tortola, British Virgin Islands. ("Seller"); and
        </li>
        <li>Token Purchaser ("Purchaser")</li>
      </ul>
      <h5>1. Sale of RENE Tokens</h5>
      <p>
        1.1. Sale of Tokens: Subject to the terms and conditions of this Agreement, Seller agrees to
        sell and transfer to Purchaser, and Purchaser agrees to purchase from Seller, a certain
        number of tokens ("RENE Tokens") of the RENE cryptocurrency project.
      </p>
      <p>
        1.2. Purchase Price: The purchase price for the RENE Tokens shall be as agreed upon between
        Seller and Purchaser in a separate pricing schedule attached hereto as Exhibit A.
      </p>
      <p>
        1.3. Payment: Purchaser shall pay the purchase price for the RENE Tokens in the manner and
        currency specified in Exhibit A.
      </p>
      <h5>2. Token Delivery</h5>
      <p>
        2.1. Delivery Date: Seller shall deliver the RENE Tokens with the vesting schedule set forth
        in Exhibit A
      </p>
      <p>
        2.2. Form of Tokens: The RENE Tokens shall be delivered to Purchaser in the form of digital
        tokens.
      </p>
      <h5>3. Representations and Warranties</h5>
      <p>
        3.1. Seller’s Representations: Seller represents and warrants to Purchaser that:
        <br />
        <span>
          a) Seller has the full right, power, and authority to sell the RENE Tokens to Purchaser.
        </span>
        <br />
        <span>
          b)The sale and transfer of RENE Tokens to Purchaser will not violate any applicable laws
          or regulations.
        </span>
      </p>
      <p>
        3.2. Purchaser’s Representations: Purchaser represents and warrants to Seller that:
        <br />
        <span>
          a) Purchaser has the necessary authority and capacity to enter into and perform its
          obligations under this Agreement.
        </span>
        <br />
        <span>
          b) Purchaser is acquiring the RENE Tokens for its own account and not with a view to
          distribute or resell them in violation of applicable securities laws.
        </span>
      </p>
      <h5>4. No Refund</h5>
      <p>
        The Purchaser acknowledges and agrees that all payments made under this Agreement are
        non-refundable and non-cancelable, except as otherwise provided herein or as required by
        applicable law.
      </p>
      <h5>5. Governing Law and Jurisdiction</h5>
      <p>
        This Agreement shall be governed by and construed in accordance with the laws of the British
        Virgin Islands, without regard to its conflict of law provisions. Any dispute arising out of
        or in connection with this Agreement shall be subject to the exclusive jurisdiction of the
        courts of the British Virgin Islands.
      </p>
      <h5>6. Terms and Conditions</h5>
      <p>6.1. Ownership: Upon purchase, Tokens are owned and controlled by the Purchaser.</p>
      <p>
        6.2. Purpose: Tokens may be used solely for purposes specified by the Seller, which may
        include but are not limited to accessing services or participating in the ReneVerse
        ecosystem.
      </p>
      <p>
        6.3. Risks: Purchasers acknowledge that the value of Tokens may fluctuate and may experience
        financial losses. Purchasers are responsible for safeguarding their Tokens and ensuring the
        security of their wallets and accounts.
      </p>
      <p>
        6.4. Disclaimer: Tokens are provided on an "as is" and "as available" basis without any
        warranties, express or implied. The Seller shall not be liable for any direct, indirect,
        incidental, special, or consequential damages arising out of or in connection with the
        purchase, sale, or use of Tokens.
      </p>
      <h5>7. Miscellaneous</h5>
      <p>
        7.1. Entire Agreement: This Agreement constitutes the entire agreement between the parties
        with respect to the subject matter hereof and supersedes all prior agreements and
        understandings, whether written or oral, relating to such subject matter.
      </p>
      <p>
        7.2. Amendments: This Agreement may only be amended or modified in writing signed by both
        parties.
      </p>
      <p>
        IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above
        written.
        <br />
      </p>
      <p className="company">LunaEdge Holdings Ltd</p>

      <h5>EXHIBIT A: Pricing Schedule</h5>
      <p>The pricing schedule for the purchase of RENE Tokens is as follows:</p>
      <ul className="example">
        <li>
          Quantity of RENE Tokens offered in Private Sale:{' '}
          {tokenAmount && formatNumber(tokenAmount)} RENE Purchase Price per RENE Token: $
          {tokenPrice} per token Vesting Period: 5% unlock at Token Generation Event (TGE) with a
          3-month cliff and subsequent 12-month vesting schedule with monthly releases. Tokens will
          be incrementally released to wallet upon unlock.
        </li>
      </ul>
    </div>
  );
};

export default Terms;
