import { Button } from '../../../../components/button/button';
import { formatDate, shortenAddress } from '../../../../utils';
import complete from '../../images/complete.png';
import './purchase-complete.scss';

const PurchaseComplete = ({
  hash,
  amount,
  reneTokens,
  address,
  close,
}: {
  hash: string;
  amount: string;
  reneTokens: number;
  address: string;
  close: () => void;
}) => {
  return (
    <div className="purchase-complete">
      <div className="purchase-complete__heading">
        <img src={complete} alt="complete" />
        <h1>Purchase complete!</h1>
        <h2>
          Thank you for participating in our private token sale! Your purchase has been successfully
          processed. Below are the details of your transaction:
        </h2>
      </div>
      <div className="purchase-complete__info">
        <div>
          <p>Transaction ID</p>
          <p>{shortenAddress(hash)}</p>
        </div>
        <div>
          <p>Amount purchased</p>
          <p>{amount} USDT</p>
        </div>
        <div>
          <p>Tokens bought</p>
          <p>{reneTokens} RENE</p>
        </div>
        <div>
          <p>Purchase date</p>
          <p>{formatDate(new Date())}</p>
        </div>
        <div>
          <p>Paid from</p>
          <p>{shortenAddress(address)}</p>
        </div>
      </div>
      <div className="purchase-complete__description">
        <p>
          Tokens will be incrementally released to your wallet upon unlock.
          <br />
          <br /> For any inquiries or assistance, feel free to reach out to our support team at
          token@reneverse.io.
          <br />
          <br /> Congratulations on becoming a part of our community! We look forward to embarking
          on this exciting journey with you.
        </p>
      </div>
      <div className="purchase-complete__action">
        <Button variant="secondary" onClick={close}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default PurchaseComplete;
