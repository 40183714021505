import { useRef, useState, useEffect } from 'react';

export interface TimeDiff {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function calculateTimeDiff(targetDate: Date): TimeDiff {
  const diff = targetDate.getTime() - Date.now();
  const days = Math.max(0, Math.floor(diff / (1000 * 60 * 60 * 24)));
  const hours = Math.max(0, Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = Math.max(0, Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = Math.max(0, Math.floor((diff % (1000 * 60)) / 1000));

  return { days, hours, minutes, seconds };
}

export function useCountdown(targetDate: Date) {
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  const [timeDiff, setTimeDiff] = useState<TimeDiff>(calculateTimeDiff(targetDate));

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => setTimeDiff(calculateTimeDiff(targetDate)), 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [targetDate]);

  return timeDiff;
}
