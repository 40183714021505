import { useState } from 'react';
import logo from '../../assets/images/logo/reneverse.svg';
import AccessCode from './access-code/access-code';
import WalletConnect from './wallet-connect/wallet-connect';

import './token-sale-page.scss';

const TokenSalePage = () => {
  const [isLogged, setIsLogged] = useState(false);

  return (
    <div className="token-sale-page">
      <img src={logo} alt="reneverse" />
      <div className="token-sale-page__main">
        {!isLogged ? (
          <AccessCode setIsLogged={setIsLogged} />
        ) : (
          <WalletConnect setIsLogged={setIsLogged} />
        )}
      </div>
    </div>
  );
};

export default TokenSalePage;
