import { useState } from 'react';
import { Button, ExternalLinkButton, LinkButton } from '../../components/button/button';
import { RENE_PORTAL_URL } from '../../global/constants';
import { Pages } from '../../global/routes';
import Card from '../../components/carousel/card/card';
import Icon from '../../components/icon/icon';
import Animate from '../../components/animate/animate';
import Carousel from '../../components/carousel/carousel';
import HeadMeta from '../../components/head-meta';
import ResponsiveImage from '../../components/responsive-image';

import slide1 from './images/image-1.webp';
import slide1Xl from './images/image-1-xl.webp';
import slide2Xl from './images/image-2-xl.webp';
import slide3Xl from './images/image-3-xl.webp';
import slide2 from './images/image-2.webp';
import slide3 from './images/image-3.webp';
import adCampaign from './images/ad-campaign.webp';

import imageVideo from './videos/1.mp4';
import videoVideo from './videos/2.mp4';
import interactiveVideo from './videos/3.mp4';
import nonInteractiveVideo from './videos/4.mp4';

import './animations.scss';
import './advertisers-page.scss';

const slides = [
  {
    img: slide1,
    imgXl: slide1Xl,
    title: 'Transparent Data on Blockchain',
    description: (
      <p>
        Ad-viewing activity and user segmentation data will be stored anonymously on blockchain.
        <br />
        <br />
        This not only ensures that the distribution of ad-dollars is transparent and equitable but
        also gives players more privacy.
      </p>
    ),
  },
  {
    img: slide2,
    imgXl: slide2Xl,

    title: 'Unrivaled Campaign Scalability',
    description: (
      <p>
        Prepare your campaign once and run it across multiple games and platforms simultaneously.
        With the ReneVerse Portal, campaign scaling is simple and gets you more reach with less time
        and resources spent.
      </p>
    ),
  },
  {
    img: slide3,
    imgXl: slide3Xl,

    title: 'Context-Driven Ad Optimization',
    description: (
      <p>
        Aside from user-preference data, ReneVerse will match your brand campaign with games that
        align with your message and target audience, so you can optimize the reach of your ad
        creative.
      </p>
    ),
  },
];

const AdvertisersPage = () => {
  const [selected, setSelected] = useState<'image' | 'video' | 'interactive' | 'non-interactive'>(
    'image',
  );

  return (
    <div className="advertisers-page">
      <HeadMeta title="Advertisers" description="Reneverse" page="home" />
      <div className="advertisers-page__landing">
        <h1>
          Reach More Gamers with Non-Intrusive <span>Borderless Ads</span>
        </h1>
        <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
          Book a Demo
        </LinkButton>
      </div>
      <Animate animateClassName="animate-ads" threshold={0.5}>
        <div className="advertisers-page__ads">
          <div className="advertisers-page__ads_title">
            <h2>CUSTOMIZE YOUR</h2>
            <h2>Advertisements</h2>
            <h3>
              Your brand campaign can be as low-lift or as involved as you want, with a variety of
              ad media and functionalities to choose from.
            </h3>
          </div>
          <div className="advertisers-page__ads_content">
            <div className="advertisers-page__ads_content_video">
              <video
                key="image"
                autoPlay
                loop
                playsInline
                className={
                  selected === 'image'
                    ? 'advertisers-page__ads_content_video--animate'
                    : 'advertisers-page__ads_content_video--back'
                }
              >
                <source src={imageVideo} />
              </video>
              <video
                key="video"
                autoPlay
                loop
                playsInline
                className={
                  selected === 'video'
                    ? 'advertisers-page__ads_content_video--animate'
                    : 'advertisers-page__ads_content_video--back'
                }
              >
                <source src={videoVideo} />
              </video>
              <video
                key="interactive"
                autoPlay
                loop
                playsInline
                className={
                  selected === 'interactive'
                    ? 'advertisers-page__ads_content_video--animate'
                    : 'advertisers-page__ads_content_video--back'
                }
              >
                <source src={interactiveVideo} />
              </video>
              <video
                key="non-interactive"
                autoPlay
                loop
                playsInline
                className={
                  selected === 'non-interactive'
                    ? 'advertisers-page__ads_content_video--animate'
                    : 'advertisers-page__ads_content_video--back'
                }
              >
                <source src={nonInteractiveVideo} />
              </video>
            </div>
            <div className="advertisers-page__ads_content_actions">
              <div>
                <Button
                  variant="text"
                  onClick={() => setSelected('image')}
                  className={selected === 'image' ? 'advertise-selected' : ''}
                >
                  <Icon name="Image" />
                  <p>Image</p>
                </Button>
                {selected === 'image' && (
                  <p>
                    Any banners, graphics, and images you have can be used here, regardless of their
                    dimensions. Bring your logos and advertisements to any in-game surface.
                  </p>
                )}
              </div>
              <div>
                <Button
                  variant="text"
                  onClick={() => setSelected('video')}
                  className={selected === 'video' ? 'advertise-selected' : ''}
                >
                  <Icon name="Video" />
                  <p>Video</p>
                </Button>
                {selected === 'video' && (
                  <p>
                    For a more eye-catching approach, all ad surfaces support video content, as
                    well. That means that billboards (and even assets like this car) can
                    automatically play your video advertisement.
                  </p>
                )}
              </div>
              <div>
                <Button
                  variant="text"
                  onClick={() => setSelected('interactive')}
                  className={selected === 'interactive' ? 'advertise-selected' : ''}
                >
                  <Icon name="Click" />
                  <p>Interactive</p>
                </Button>
                {selected === 'interactive' && (
                  <p>
                    For both image and video formats, Borderless Ads offer simple interactivity that
                    directs the user toward conversion outside their play session to preserve
                    immersion.
                  </p>
                )}
              </div>
              <div>
                <Button
                  variant="text"
                  onClick={() => setSelected('non-interactive')}
                  className={selected === 'non-interactive' ? 'advertise-selected' : ''}
                >
                  <Icon name="Pyramid" />
                  <p>Non-interactive</p>
                </Button>
                {selected === 'non-interactive' && (
                  <p>
                    For more discreet brand awareness campaigns, non-interactive ads provide a
                    view-only experience that prevents any intrusion upon the player experience.
                  </p>
                )}
              </div>
              <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
                Book a demo
              </LinkButton>
            </div>
          </div>
        </div>
      </Animate>
      {/* <Animate animateClassName="animate-branded">
        <div className="advertisers-page__branded">
          <div>
            <h2>Virtual Product Placement</h2>
            <h2>Bespoke Branded Asset</h2>
          </div>
          <video src=""></video>
          <p>
            Like never before, brands can create their own 3D Branded Asset models that are tied to
            brand campaigns and offer unparalleled customization. Whether used as a hyper-realistic
            product placement or a fun, interactive item, Branded Assets offer users far more
            enjoyable ways to interact with brands, leading to higher conversions.
          </p>
        </div>
      </Animate> */}
      <Animate animateClassName="animate-benefits" threshold={0.5}>
        <div className="advertisers-page__benefits">
          <div>
            <h2>FOR YOUR BRAND</h2>
            <h2>Benefits</h2>
          </div>
          <Carousel slides={slides} SlideComponent={Card} />
        </div>
      </Animate>
      {/* <Animate animateClassName="animate-promo">
        <div className="advertisers-page__promo">
          <div>
            <h2>BORDERLESS ADS</h2>
            <h2>Promo Video</h2>
          </div>
          <video src=""></video>
        </div>
      </Animate> */}
      <Animate animateClassName="animate-portal">
        <div className="advertisers-page__portal">
          <ResponsiveImage sm={adCampaign} alt="portal" />
          <div>
            <div>
              <h2>YOUR AD CAMPAIGN</h2>
              <h2>Portal</h2>
            </div>
            <p>
              Upload your ads, manage ongoing campaigns, and even add Branded Assets easily in the
              ReneVerse Portal - your one-stop shop for controlling your brand’s Borderless Ads.
            </p>
            <ExternalLinkButton variant="secondary" href={RENE_PORTAL_URL}>
              Visit Portal
            </ExternalLinkButton>
          </div>
        </div>
      </Animate>
      <Animate animateClassName="animate-signup">
        <div className="advertisers-page__signup">
          <h3>Sign up for Pilot & enjoy up to 60% off CPC/CPM for 3 months.</h3>
          <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
            Sign up now
          </LinkButton>
        </div>
      </Animate>
    </div>
  );
};

export default AdvertisersPage;
