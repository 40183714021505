export type Event = {
  Input: React.ChangeEvent<HTMLInputElement>;
  TextArea: React.ChangeEvent<HTMLTextAreaElement>;
  Button: React.MouseEvent<HTMLButtonElement>;
  Select: React.ChangeEvent<HTMLSelectElement>;
  Div: React.MouseEvent<HTMLDivElement>;
  Keyboard: React.KeyboardEvent<HTMLInputElement>;
};

export const handleKeyPressSubmit = (e: Event['Keyboard'], callback: any) => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
    callback();
  }
};

export const shortenAddress = (
  address: string,
  startLength: number = 4,
  endLength: number = 4,
): string => {
  if (address.length < startLength + endLength + 2) {
    return address;
  }
  return `${address.slice(0, startLength + 2)}...${address.slice(-endLength)}`;
};

export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const formatDate = (dateInput: Date): string => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return dateInput.toLocaleDateString('en-US', options);
};
