import { memo, useEffect } from 'react';
import { useCountdown } from './useCountdown';
import './countdown-timer.scss';

interface CountdownTimerProps {
  targetDate: string;
  shorten?: boolean;
  countdownEndHandler: () => void;
}

const CountdownSection: React.FC<{ value: number; label: string; shorten: boolean }> = ({
  value,
  label,
  shorten,
}) => {
  const formattedValue = shorten ? `${value}${label.slice(0, 1)}` : value;
  return (
    <div className="countdown-timer__section">
      <span className="countdown-timer__value" data-value={value}>
        {formattedValue}
      </span>
      {!shorten && <span className="countdown-timer__label">{label}</span>}
    </div>
  );
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
  shorten = false,
  countdownEndHandler,
}) => {
  const date = new Date(targetDate);
  const { days, hours, minutes, seconds } = useCountdown(date);

  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      countdownEndHandler();
    }
  }, [countdownEndHandler, days, hours, minutes, seconds]);

  return (
    <div className="countdown-timer" aria-label="Countdown Timer">
      <CountdownSection value={days} label="days" shorten={shorten} />
      <CountdownSection value={hours} label="hours" shorten={shorten} />
      <CountdownSection value={minutes} label="mins" shorten={shorten} />
      <CountdownSection value={seconds} label="secs" shorten={shorten} />
    </div>
  );
};

export default memo(CountdownTimer);
