import axiosPrivateSale from '../../../global/axios/axiosTokenSale';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/scaffold-react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { Button } from '../../../components/button/button';
import PurchaseCoins from './purchase-coins/purchase-coins';
import ConfirmPurchase from './confirm-purchase/confirm-purchase';

import './wallet-connect.scss';

type SaleData = {
  availableReneTokens: number;
  boughtReneTokens: number;
  initialOfferingRene: number;
  initialPriceRene: number;
  minInvestmentUsdt: number;
  reneWalletAddress: string;
};

const WalletIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M19.5 16V19C19.5 19.2652 19.3946 19.5196 19.2071 19.7071C19.0196 19.8946 18.7652 20 18.5 20H6.5C5.96957 20 5.46086 19.7893 5.08579 19.4142C4.71071 19.0391 4.5 18.5304 4.5 18V6M17.5 8V5C17.5 4.73478 17.3946 4.48043 17.2071 4.29289C17.0196 4.10536 16.7652 4 16.5 4H6.5C5.96957 4 5.46086 4.21071 5.08579 4.58579C4.71071 4.96086 4.5 5.46957 4.5 6C4.5 6.53043 4.71071 7.03914 5.08579 7.41421C5.46086 7.78929 5.96957 8 6.5 8H18.5C18.7652 8 19.0196 8.10536 19.2071 8.29289C19.3946 8.48043 19.5 8.73478 19.5 9V12L17.5 8Z"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5 12V16H16.5C15.9696 16 15.4609 15.7893 15.0858 15.4142C14.7107 15.0391 14.5 14.5304 14.5 14C14.5 13.4696 14.7107 12.9609 15.0858 12.5858C15.4609 12.2107 15.9696 12 16.5 12H20.5Z"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const WalletConnect = ({ setIsLogged }: { setIsLogged: Dispatch<SetStateAction<boolean>> }) => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [saleData, setSaleData] = useState<SaleData>();
  const [purchaseAmount, setPurchaseAmount] = useState<string>('');

  useEffect(() => {
    axiosPrivateSale
      .get('private-sale-details')
      .then((data: { data: SaleData }) => {
        setSaleData(data.data);
      })
      .catch((err: AxiosError) => {
        sessionStorage.clear();
        setIsLogged(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConnected) {
      axiosPrivateSale.post('associate-wallet', {
        walletAddress: address,
      });
    }
  }, [address, isConnected]);
  const tokensSold =
    saleData && (saleData.availableReneTokens * 100) / saleData.initialOfferingRene;
  return (
    <>
      {!purchaseAmount && (
        <>
          <div className="wallet-connect">
            <div className="wallet-connect__heading">
              <h1>Private RENE Token Sale</h1>
            </div>
            <div className="wallet-connect__info">
              <div>
                {/* <p>Total allocation</p>
                <p className="rene-token">
                  {saleData?.initialOfferingRene
                    ? formatNumber(saleData.initialOfferingRene as number)
                    : ''}{' '}
                  RENE
                </p> */}
              </div>
              <div>
                <p>Price per token</p>
                <p>{saleData?.initialPriceRene} USDT</p>
              </div>
              <div>
                <p>Min investment</p>
                <p>{saleData?.minInvestmentUsdt} USDT</p>
              </div>
            </div>
            {!address ? (
              <div className="wallet-connect__connect">
                <p>To continue purchase, please connect your wallet</p>
                <Button icon={<WalletIcon />} variant="secondary" onClick={() => open()}>
                  Connect Wallet
                </Button>
              </div>
            ) : (
              <PurchaseCoins
                address={address}
                availableTokens={saleData?.availableReneTokens as number}
                tokensSold={tokensSold}
                tokenPrice={saleData?.initialPriceRene}
                setIsPurchase={setPurchaseAmount}
                reneWalletAddress={saleData?.reneWalletAddress as string}
                minInvestmentUsdt={saleData?.minInvestmentUsdt as number}
              />
            )}
          </div>
        </>
      )}
      {address && purchaseAmount ? (
        <ConfirmPurchase
          amount={purchaseAmount}
          address={address}
          reneAllocation={saleData?.initialOfferingRene as number}
          tokenPrice={saleData?.initialPriceRene as number}
          setPurchaseAmount={setPurchaseAmount}
          reneWalletAddress={saleData?.reneWalletAddress as string}
        />
      ) : null}
    </>
  );
};

export default WalletConnect;
