import axiosPrivateSale from '../../../global/axios/axiosTokenSale';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { handleKeyPressSubmit } from '../../../utils';
import { Button } from '../../../components/button/button';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';
import './access-code.scss';

const AccessCode = ({ setIsLogged }: { setIsLogged: Dispatch<SetStateAction<boolean>> }) => {
  const [accessCode, setAccessCode] = useState('');
  const [wrongCode, setWrongCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const sessionAccessCode = sessionStorage.getItem('accessCode');

  const handleVerify = useCallback(() => {
    setIsLoading(true);
    if (accessCode) sessionStorage.setItem('accessCode', accessCode);
    axiosPrivateSale
      .get('private-sale-syndicate')
      .then(() => {
        setIsLogged(true);
      })
      .catch((error: AxiosError) => {
        console.log(error);
        if (error.response?.status === 401) {
          setWrongCode('Wrong access key, please try again or contact support.');
          sessionStorage.clear();
        }

        if (error.message === 'Network Error') {
          setWrongCode('Not available in your country');
          sessionStorage.clear();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessCode, setIsLogged]);

  useEffect(() => {
    if (sessionAccessCode && !accessCode) {
      setIsLogged(true);
    }
  }, [sessionAccessCode, handleVerify, accessCode, setIsLogged]);

  const onChange = (e: any) => {
    if (wrongCode) setWrongCode('');
    setAccessCode(e.target.value);
  };

  return (
    <div className="access-code">
      <div className="access-code__heading">
        <h1>Private RENE Token Sale</h1>
        <h2>Please enter the access key you received.</h2>
      </div>
      <div className="access-code__otp">
        <h3>Access Key</h3>
        <Input
          placeholder="Enter access key"
          className={wrongCode ? 'access-code__otp_error' : ''}
          value={accessCode}
          handleInput={onChange}
          onKeyDown={(e) => handleKeyPressSubmit(e, handleVerify)}
          errorMessage={wrongCode}
        />
      </div>
      <Button variant="secondary" onClick={handleVerify} disabled={!accessCode}>
        {isLoading ? <Spinner /> : 'Continue'}
      </Button>
    </div>
  );
};

export default AccessCode;
