import './checkbox.scss';

const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M2.5 6L5 8.5L10 3.5"
      stroke="#F5F5F5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Checkbox = ({
  value,
  children,
  setValue,
}: {
  value: boolean;
  children?: JSX.Element | string;
  setValue: (value: boolean) => void;
}) => {
  return (
    <div className="checkbox-container">
      <button
        className={`checkbox checkbox${value && '--checked'}`}
        type="button"
        onClick={() => setValue(!value)}
      >
        {value ? <Check /> : null}
      </button>
      {children}
    </div>
  );
};

export default Checkbox;
